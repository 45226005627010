import {Category} from "../../entities/Category";
import {CategoriesJson} from "./Categories.json";
import {defineCurrentLanguage} from "../../../presentation/extensions/LanguageExtension";

export class CategoriesRepository {

    loadCategories(): Category[] {
        const currentLanguage = defineCurrentLanguage()

        return CategoriesJson.map(categoryJson => {
            return {
                id: categoryJson.id,
                imageUrl: categoryJson.imageUrl,
                name: categoryJson.name.find((nameVariant) => {
                    return nameVariant.languageCode === currentLanguage;
                })!!.displayName
            }
        })
    }

    loadCategory(categoryId: string): Category {
        const categories = this.loadCategories()
        return categories.find(category => category.id === categoryId)!!
    }
}