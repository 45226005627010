import styles from "./HeaderMenuItemComponent.module.scss"
import React, {ReactNode, useState} from "react";
import {DropdownMenuComponent, DropdownMenuItem} from "../../dropdown-menu/DropdownMenuComponent";
import {BsArrowDownShort, BsFillArrowDownCircleFill} from "react-icons/bs";
import {AiOutlineArrowDown} from "react-icons/ai";

export interface HeaderMenuItemComponentProps {
    readonly onHeaderMenuItemClicked?: () => void
    readonly onHeaderDropdownMenuItemClicked?: ((id: string) => void)
    readonly iconType: ReactNode
    readonly title: string
    readonly items?: DropdownMenuItem[]
}

export function HeaderMenuItemComponent(
    {
        onHeaderMenuItemClicked,
        onHeaderDropdownMenuItemClicked,
        iconType,
        title,
        items
    }: HeaderMenuItemComponentProps
) {
    const [needDropdown, setNeedDropdown] = useState(false)

    return (
        <div className={styles.container}
             onMouseOver={() => setNeedDropdown(true)}
             onMouseOut={() => setNeedDropdown(false)}>
            <div className={styles.menuButton} onClick={onHeaderMenuItemClicked}>
                {iconType}
                {title}
            </div>

            {needDropdown && (items?.length ?? 0) > 0 ?
                <DropdownMenuComponent
                    onDropdownMenuItemClicked={(id: string) => onHeaderDropdownMenuItemClicked?.(id)}
                    items={items!}/> : null
            }
        </div>
    )
}