import styles from "./MainPage.module.scss";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {FooterComponent} from "../../components/footer/FooterComponent";
import {ProductComponent} from "../../components/product/ProductComponent";
import {ProductsRepository} from "../../../data/repositories/products/ProductsRepository";
import {navigateToProduct} from "../../extensions/NavigationExtension";
import {Product} from "../../../data/entities/Product";

export function MainPage() {
    let products = new ProductsRepository().loadProducts()

    function randomProduct(): Product {
        const randomProduct = products[Math.floor(Math.random() * products.length)]
        products = products.filter((product) => product !== randomProduct)
        return randomProduct
    }

    function openProduct(product: Product) {
        navigateToProduct(product.categoryId, product.id)
    }

    return (
        <div>
            <HeaderComponent/>

            <div className={styles.welcomeSection}>
                <img src={"/media/backgrounds/low/background.png"}/>
            </div>

            <div className={styles.productsSection}>
                <ProductComponent product={randomProduct()}
                                  onProductClicked={product => openProduct(product)}/>
                <ProductComponent product={randomProduct()}
                                  onProductClicked={product => openProduct(product)}/>
                <ProductComponent product={randomProduct()}
                                  onProductClicked={product => openProduct(product)}/>
            </div>

            <FooterComponent/>
        </div>
    )
}