import {defineCurrentLanguage} from "./LanguageExtension";

export function navigateToHome() {
    navigateToUrl("/")
}

export function navigateToProducts(categoryId: string) {
    navigateToUrl("/products/" + categoryId)
}

export function navigateToProduct(categoryId: string, productId: string) {
    navigateToUrl("/products/" + categoryId + "/" + productId)
}

export function navigateToGallery() {
    navigateToUrl("/gallery/")
}

export function navigateToAboutUs() {
    navigateToUrl("/about-us/")
}

export function navigateToContacts() {
    navigateToUrl("/contacts/")
}

export function navigateToUrl(path: string) {
    const language = defineCurrentLanguage()
    let startPath = "/" + language;

    if (!path.startsWith("/")) {
        startPath = startPath + "/"
    }

    window.open(startPath + path, "_self")
}

export function openInNewWindow(url: string) {
    window.open(url)
}