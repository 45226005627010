export const GalleryJson = {
    videos: [
        "/media/gallery/videos/1.MP4",
        "/media/gallery/videos/2.MOV",
        "/media/gallery/videos/3.MOV",
        "/media/gallery/videos/4.MOV",
        "/media/gallery/videos/5.MOV",
        "/media/gallery/videos/6.MOV",
        "/media/gallery/videos/7.MOV",
    ],
    images: [
        "/media/gallery/images/low/1.jpeg",
        "/media/gallery/images/low/2.jpeg",
        "/media/gallery/images/low/3.jpeg",
        "/media/gallery/images/low/4.jpeg",
        "/media/gallery/images/low/5.jpeg",
        "/media/gallery/images/low/6.jpeg",
    ]
}