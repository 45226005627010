import {DropdownMenuItemComponent} from "./menu-item/DropdownMenuItemComponent";
import styles from "./DropdownMenuComponent.module.scss";

export type DropdownMenuItem = { id: string, title: string }

export interface DropdownMenuComponentProps {
    readonly onDropdownMenuItemClicked: (id: string) => void
    readonly items: DropdownMenuItem[]
}

export function DropdownMenuComponent(
    {onDropdownMenuItemClicked, items}: DropdownMenuComponentProps
) {
    return (
        <div className={styles.container}>
            {items.map((item: DropdownMenuItem) => (
                <DropdownMenuItemComponent
                    onDropdownMenuItemClicked={onDropdownMenuItemClicked}
                    id={item.id}
                    title={item.title}/>
            ))}
        </div>
    )
}