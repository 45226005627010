export default abstract class TextProvider {

    abstract languageCode(): string

    abstract language(): string

    abstract home(): string

    abstract products(): string

    abstract gallery(): string

    abstract aboutUs(): string

    abstract contacts(): string

    abstract origin(): string

    abstract packaging(): string

    abstract moreDetails(): string

    abstract leaveRequest(): string

    abstract send(): string

    abstract name(): string

    abstract email(): string

    abstract phone(): string

    abstract message(): string

    abstract aboutCompany(): string

    abstract aboutUsDescription(): string

    abstract photos(): string

    abstract videos(): string

    abstract country(): string

    abstract requestSuccess(): string

    abstract requestError(): string

    companyAddress(): string {
        return "421, BUILDING B1, ZONE B, FUHAI COMMUNITY, FUYONG STREET, BAOAN DISTRICT, SHENZHEN, CHINA"
    }

    companyName(): string {
        return "Karavan Alliance Trading Co., Ltd."
    }

    companyEmailUrl(): string {
        return "mailto:info@karavan.cn.com"
    }

    formattedCompanyEmail(): string {
        return "info@karavan.cn.com"
    }

    companyPhoneUrl(): string {
        return "tel:+8618589949129"
    }

    formattedCompanyPhone(): string {
        return "+86 185 8994 9129"
    }
}
