import {BsFillArrowRightCircleFill} from "react-icons/bs";
import styles from "./DropdownMenuItemComponent.module.scss";

export interface DropdownMenuItemComponentProps {
    readonly onDropdownMenuItemClicked: (id: string) => void
    readonly id: string
    readonly title: string
}

export function DropdownMenuItemComponent(
    {onDropdownMenuItemClicked, id, title}: DropdownMenuItemComponentProps
) {
    return (
        <div className={styles.container} onClick={() => onDropdownMenuItemClicked(id)}>
            {title}
            <BsFillArrowRightCircleFill/>
        </div>
    )
}