import React, {useRef, useState} from "react";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {FooterComponent} from "../../components/footer/FooterComponent";
import {useParams} from "react-router-dom";
import styles from "./ProductPage.module.scss";
import {ProductsRepository} from "../../../data/repositories/products/ProductsRepository";
import {defineTextProvider} from "../../extensions/LanguageExtension";
import {sendForm} from "@emailjs/browser";

export function ProductPage() {
    const [isSendingEmail, setSendingEmail] = useState(false)
    const params = useParams()
    const formSectionReference = useRef<HTMLDivElement>(null)
    const formReference = useRef<HTMLFormElement>(null)
    const textProvider = defineTextProvider()

    const categoryId = params.categoryId!!
    const productId = params.productId!!
    const product = new ProductsRepository().loadProduct(categoryId, productId);

    function scrollToFormSection() {
        formSectionReference.current?.scrollIntoView({behavior: 'smooth'});
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        if (isSendingEmail) return
        setSendingEmail(true)

        sendForm('service_cocp3km', 'template_ev458gu', formReference.current!!, "1ZPNvjk0HX4ZFsUJF")
            .then(function (response) {
                alert(textProvider.requestSuccess())
            }, function (error) {
                alert(textProvider.requestError())
            })
            .finally(function () {
                formReference.current!!.reset()
                setSendingEmail(false)
            });
    };

    return (
        <div>
            <HeaderComponent/>

            <div className={styles.productSectionContainer}>
                <img className={styles.productImage} src={product.imageUrl}/>
                <div className={styles.dividerView}></div>
                <div className={styles.detailsView}>
                    <h1>{product.name}</h1>
                    <div className={styles.detailsItemView}>
                        <text>{textProvider.origin()}</text>
                        <text>{product.origin}</text>
                    </div>
                    <div className={styles.detailsSeparatorView}></div>
                    <div className={styles.detailsItemView}>
                        <text>{textProvider.packaging()}</text>
                        <text>{product.packaging}</text>
                    </div>
                    <div className={styles.detailsSeparatorView}></div>
                    <button onClick={scrollToFormSection}>
                        {textProvider.leaveRequest()}
                    </button>
                </div>
            </div>

            <div className={styles.formSectionContainer} ref={formSectionReference}>
                <img src={"/media/backgrounds/low/3.webp"}/>
                <h1>{textProvider.leaveRequest()}</h1>
                <form id={styles.formContainer}
                      ref={formReference}
                      onSubmit={e => handleSubmit(e)}>
                    <input id={styles.nameInput}
                           type={"text"}
                           name={"name"}
                           placeholder={textProvider.name()}
                           required={true}/>
                    <input id={styles.emailInput}
                           type={"email"}
                           name={"email"}
                           placeholder={textProvider.email()}
                           required={true}/>
                    <input id={styles.phoneInput}
                           type={"tel"}
                           name={"phone"}
                           placeholder={textProvider.phone()}
                           required={true}/>
                    <input id={styles.countryInput}
                           type={"text"}
                           name={"country"}
                           placeholder={textProvider.country()}
                           required={true}/>
                    <textarea id={styles.messageInput}
                              name={"message"}
                              placeholder={textProvider.message()}
                              required={true}/>

                    <div id={styles.submitContainer}>
                        <input id={styles.submitButton}
                               type={"submit"}
                               value={isSendingEmail ? "" : textProvider.send()}/>
                        {isSendingEmail ? <div className={styles.loader}/> : null}
                    </div>
                </form>
            </div>

            <FooterComponent/>
        </div>
    )
}