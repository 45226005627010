import styles from "./ContactsPage.module.scss";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {BsPersonFill} from "react-icons/bs";
import {ImPhone} from "react-icons/im";
import {FooterComponent} from "../../components/footer/FooterComponent";
import {defineCurrentLanguage, defineTextProvider} from "../../extensions/LanguageExtension";
import {HiMail} from "react-icons/hi";

export function ContactsPage() {
    const textProvider = defineTextProvider()
    const language = defineCurrentLanguage()

    return (
        <div>
            <HeaderComponent/>

            <div className={styles.container}>
                <div className={styles.mapContainer}>
                    <iframe
                        title={"1"}
                        src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7811.208335407908!2d113.81780210474135!3d22.67094812365817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340394c354015a19%3A0x66f5ab63a7d543ee!2zRnV5b25nIFN1YmRpc3RyaWN0LCBCYW9hbiwg0KjRjdC90YzRh9C20Y3QvdGMLCDQk9GD0LDQvdC00YPQvSwg0JrQuNGC0LDQuSwgNTE4MTAz!5e0!3m2!1s" + language + "!2s!4v1692455493330!5m2!1s" + language + " !2s"}
                        width="100%"
                        height="100%"
                        loading={"eager"}
                        frameBorder={0}
                        referrerPolicy="no-referrer-when-downgrade"/>
                </div>

                <div className={styles.detailsContainer}>
                    <div className={styles.contactsDetailsItem}>
                        <BsPersonFill className={styles.contactsDetailsIcon}/>
                        <span className={styles.contactsDetailsTitle}>{textProvider.companyName()}</span>
                    </div>
                    <div className={styles.contactsDetailsItem}>
                        <HiMail className={styles.contactsDetailsIcon}/>
                        <a className={styles.contactsDetailsTitle}
                           href={textProvider.companyEmailUrl()}>{textProvider.formattedCompanyEmail()}</a>
                    </div>
                    <div className={styles.contactsDetailsItem}>
                        <ImPhone className={styles.contactsDetailsIcon}/>
                        <a className={styles.contactsDetailsTitle}
                           href={textProvider.companyPhoneUrl()}>{textProvider.formattedCompanyPhone()}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}