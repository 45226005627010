import TextProvider from "./TextProvider";

export default class ZhTextProvider extends TextProvider {

    languageCode(): string {
        return "zh"
    }

    language(): string {
        return "中文"
    }

    home(): string {
        return "主页"
    }

    products(): string {
        return "产品"
    }

    gallery(): string {
        return "画廊"
    }

    aboutUs(): string {
        return "关于我们"
    }

    contacts(): string {
        return "往来"
    }

    email(): string {
        return "电子邮件";
    }

    leaveRequest(): string {
        return "留下请求";
    }

    message(): string {
        return "信息";
    }

    moreDetails(): string {
        return "更多细节";
    }

    name(): string {
        return "名称";
    }

    origin(): string {
        return "原产地";
    }

    packaging(): string {
        return "包装";
    }

    phone(): string {
        return "电话";
    }

    send(): string {
        return "发送";
    }

    aboutCompany(): string {
        return "关于公司";
    }

    photos(): string {
        return "照片";
    }

    videos(): string {
        return "视频";
    }

    country(): string {
        return "国家";
    }

    requestSuccess(): string {
        return "您的申请已成功发送！我们将使用申请中指定的联系方式尽快与您联系。";
    }

    requestError(): string {
        return "尝试提交您的申请时发生错误。再试一次。";
    }

    aboutUsDescription(): string {
        return "\"喀拉湾进出口有限公司\" 是一家专门从事各种商品和服务销售的国际贸易公司。我们致力于与其他公司和组织建立合作伙伴关系，进行互相支持和协作。\n\n" +
            "我们的公司建立在质量、可靠性和责任的原则之上。我们提供满足客户需求和要求的各种商品和服务。我们经验丰富、专业的员工致力于提供高水平的服务，满足全球市场客户的需求。\n\n" +
            "我们专注于国际贸易，并在不同国家建立了广泛的合作伙伴和供应商网络。这使我们能够迅速、高效地为客户提供具有竞争力价格的优质商品和服务。\n\n" +
            "我们为与客户和合作伙伴的长期关系感到自豪，并致力于开展新的合作伙伴关系，扩大我们的业务范围。我们的公司秉承商业道德和职业道德原则，并致力于在国际贸易领域实现持续增长和成功。\n\n";
    }
}
