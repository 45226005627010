export const SoftCandyJson = [
    {
        "id": "4_1",
        "imageUrl": "/media/products/low/4_1.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Mango flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом манго"
            },
            {
                "languageCode": "zh",
                "displayName": "芒果口味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_2",
        "imageUrl": "/media/products/low/4_2.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Passion fruit flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом маракуя"
            },
            {
                "languageCode": "zh",
                "displayName": "百香果味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_3",
        "imageUrl": "/media/products/low/4_3.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Grape flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом винограда"
            },
            {
                "languageCode": "zh",
                "displayName": "葡萄味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_4",
        "imageUrl": "/media/products/low/4_4.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Pineapple flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом ананаса"
            },
            {
                "languageCode": "zh",
                "displayName": "菠萝味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_5",
        "imageUrl": "/media/products/low/4_5.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Banana flavor"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом банана"
            },
            {
                "languageCode": "zh",
                "displayName": "香蕉味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_6",
        "imageUrl": "/media/products/low/4_6.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Kiwi flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом киви"
            },
            {
                "languageCode": "zh",
                "displayName": "猕猴桃味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_7",
        "imageUrl": "/media/products/low/4_7.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Cherry flavor"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом вишни"
            },
            {
                "languageCode": "zh",
                "displayName": "樱桃味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_8",
        "imageUrl": "/media/products/low/4_8.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Guava flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом гуавы"
            },
            {
                "languageCode": "zh",
                "displayName": "番石榴味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_9",
        "imageUrl": "/media/products/low/4_9.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "With tangerine flavor"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом мандарина"
            },
            {
                "languageCode": "zh",
                "displayName": "带有橘子味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_10",
        "imageUrl": "/media/products/low/4_10.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Strawberry flavor"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом клубники"
            },
            {
                "languageCode": "zh",
                "displayName": "草莓味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_11",
        "imageUrl": "/media/products/low/4_11.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Orange flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом апельсина"
            },
            {
                "languageCode": "zh",
                "displayName": "橙子味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
    {
        "id": "4_12",
        "imageUrl": "/media/products/low/4_12.jpg",
        "categoryId": "4",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Cherry flavored"
            },
            {
                "languageCode": "ru",
                "displayName": "Со вкусом черешни"
            },
            {
                "languageCode": "zh",
                "displayName": "樱桃味"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "500 gram"
            },
            {
                "languageCode": "ru",
                "displayName": "500 грамм"
            },
            {
                "languageCode": "zh",
                "displayName": "500 克"
            }
        ]
    },
]

export const NutsJson = [
    {
        "id": "1_1",
        "imageUrl": "/media/products/low/1_1.jpg",
        "categoryId": "1",
        "availableVariety": ["33", "185"],
        "name": [
            {
                "languageCode": "en",
                "displayName": "Walnut in shell"
            },
            {
                "languageCode": "ru",
                "displayName": "Грецкий орех"
            },
            {
                "languageCode": "zh",
                "displayName": "核桃"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "10|25 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "10|25 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "10|25公斤"
            }
        ]
    },
    {
        "id": "1_2",
        "imageUrl": "/media/products/low/1_2.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Shelled walnuts"
            },
            {
                "languageCode": "ru",
                "displayName": "Очищенный грецкий орех"
            },
            {
                "languageCode": "zh",
                "displayName": "带壳核桃"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
    {
        "id": "1_3",
        "imageUrl": "/media/products/low/1_3.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Pecan in shell"
            },
            {
                "languageCode": "ru",
                "displayName": "Орех пекан в скорлупе"
            },
            {
                "languageCode": "zh",
                "displayName": "碧根果"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "1|5 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "1|5 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "1|5公斤"
            }
        ]
    },
    {
        "id": "1_4",
        "imageUrl": "/media/products/low/1_4.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Roasted macadamia nuts"
            },
            {
                "languageCode": "ru",
                "displayName": "Макадамия"
            },
            {
                "languageCode": "zh",
                "displayName": "夏威夷果"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "1|5 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "1|5 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "1|5公斤"
            }
        ]
    },
    {
        "id": "1_5",
        "imageUrl": "/media/products/low/1_5.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Walnut kernels yunnan"
            },
            {
                "languageCode": "ru",
                "displayName": "Грецкий орех очищенный yunnan"
            },
            {
                "languageCode": "zh",
                "displayName": "云南核桃仁"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
    {
        "id": "1_6",
        "imageUrl": "/media/products/low/1_6.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Walnut kernels siner"
            },
            {
                "languageCode": "ru",
                "displayName": "Грецкий орех очищенный siner"
            },
            {
                "languageCode": "zh",
                "displayName": "新二核桃仁"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
    {
        "id": "1_7",
        "imageUrl": "/media/products/low/1_7.jpg",
        "categoryId": "1",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Walnut kernels xingfu"
            },
            {
                "languageCode": "ru",
                "displayName": "Грецкий орех очищенный xingfu"
            },
            {
                "languageCode": "zh",
                "displayName": "幸福核桃仁"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
]

export const PreservedFruitsJson = [
    {
        "id": "2_1",
        "imageUrl": "/media/products/low/2_1.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried mango slices"
            },
            {
                "languageCode": "ru",
                "displayName": "Манго сушенное"
            },
            {
                "languageCode": "zh",
                "displayName": "芒果干"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "1 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "1 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "1公斤"
            }
        ]
    },
    {
        "id": "2_2",
        "imageUrl": "/media/products/low/2_2.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried papaya dices"
            },
            {
                "languageCode": "ru",
                "displayName": "Папая кубик"
            },
            {
                "languageCode": "zh",
                "displayName": "木瓜丁"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "1 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "1 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "1公斤"
            }
        ]
    },
    {
        "id": "2_3",
        "imageUrl": "/media/products/low/2_3.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried pulm"
            },
            {
                "languageCode": "ru",
                "displayName": "Чернослив сушенный"
            },
            {
                "languageCode": "zh",
                "displayName": "西梅干（李子干）"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "10公斤"
            }
        ]
    },
    {
        "id": "2_4",
        "imageUrl": "/media/products/low/2_4.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried raisins"
            },
            {
                "languageCode": "ru",
                "displayName": "Изюм"
            },
            {
                "languageCode": "zh",
                "displayName": "葡萄干"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
    {
        "id": "2_5",
        "imageUrl": "/media/products/low/2_5.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried abricot"
            },
            {
                "languageCode": "ru",
                "displayName": "Абрикос сушенный"
            },
            {
                "languageCode": "zh",
                "displayName": "杏干"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "Uzbekistan|Tajikistan"
            },
            {
                "languageCode": "ru",
                "displayName": "Узбекистан|Таджикистан"
            },
            {
                "languageCode": "zh",
                "displayName": "乌兹别克斯坦|塔吉克斯坦"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|10 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|10 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|10公斤"
            }
        ]
    },
    {
        "id": "2_6",
        "imageUrl": "/media/products/low/2_6.jpg",
        "categoryId": "2",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Kunkwat"
            },
            {
                "languageCode": "ru",
                "displayName": "Кункват"
            },
            {
                "languageCode": "zh",
                "displayName": "昆克瓦特"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "1|2.5 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "1|2.5 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "1|2.5公斤"
            }
        ]
    },
]

export const SeedsJson = [
    {
        "id": "3_1",
        "imageUrl": "/media/products/low/3_1.jpg",
        "categoryId": "3",
        "availableVariety": ["11", "13"],
        "name": [
            {
                "languageCode": "en",
                "displayName": "Snow white pumpkin seeds"
            },
            {
                "languageCode": "ru",
                "displayName": "Тыквенная семечка «белоснежка»"
            },
            {
                "languageCode": "zh",
                "displayName": "雪白南瓜子"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "10|25 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "10|25 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "10|25公斤"
            }
        ]
    },
    {
        "id": "3_2",
        "imageUrl": "/media/products/low/3_2.jpg",
        "categoryId": "3",
        "availableVariety": ["A", "AA"],
        "name": [
            {
                "languageCode": "en",
                "displayName": "Shine skin pumpkin seed kernels"
            },
            {
                "languageCode": "ru",
                "displayName": "Тыквенная семечка «шайн скин» очищенные"
            },
            {
                "languageCode": "zh",
                "displayName": "光板瓜子仁"
            }
        ],
        "origin": [
            {
                "languageCode": "en",
                "displayName": "China"
            },
            {
                "languageCode": "ru",
                "displayName": "Китай"
            },
            {
                "languageCode": "zh",
                "displayName": "中国"
            }
        ],
        "packaging": [
            {
                "languageCode": "en",
                "displayName": "5|25 kilograms"
            },
            {
                "languageCode": "ru",
                "displayName": "5|25 килограмм"
            },
            {
                "languageCode": "zh",
                "displayName": "5|25公斤"
            }
        ]
    },
]