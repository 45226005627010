import styles from "./ProductComponent.module.scss"
import {Product} from "../../../data/entities/Product";
import {defineTextProvider} from "../../extensions/LanguageExtension";

export interface ProductComponentProps {
    readonly product: Product,
    readonly onProductClicked: (product: Product) => void
}

export function ProductComponent(
    {product, onProductClicked}: ProductComponentProps
) {
    const textProvider = defineTextProvider()

    return (
        <div className={styles.container} onClick={() => onProductClicked(product)}>
            <img className={styles.productImage} src={product.imageUrl}/>
            <text className={styles.productText}>{product.name}</text>
            <button className={styles.detailsButton}>{textProvider.moreDetails()}</button>
        </div>
    );
}