import {GalleryJson} from "./Gallery.json";
import {Gallery} from "../../entities/Gallery";

export class GalleryRepository {

    loadGallery(): Gallery {
        return {
            images: GalleryJson.images,
            videos: GalleryJson.videos,
        } as Gallery
    }
}