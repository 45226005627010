import styles from "./GalleryPage.module.scss";
import {defineTextProvider} from "../../extensions/LanguageExtension";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {FooterComponent} from "../../components/footer/FooterComponent";
import {GalleryRepository} from "../../../data/repositories/gallery/GalleryRepository";
import {FaImage, FaVideo} from "react-icons/fa6";

export function GalleryPage() {
    const textProvider = defineTextProvider()
    const gallery = new GalleryRepository().loadGallery()


    return (
        <div>
            <HeaderComponent/>

            <div className={styles.container}>
                <h1><FaImage/>{textProvider.photos()}</h1>

                <div className={styles.mediaContainer}>
                    {gallery.images.map(imageSrc =>
                        <img src={imageSrc}/>
                    )}
                </div>

                <h1><FaVideo/>{textProvider.videos()}</h1>

                <div className={styles.mediaContainer}>
                    {gallery.videos.map(videoSrc =>
                        <video src={videoSrc} autoPlay={false} controls={true} preload={"metadata"}/>
                    )}
                </div>
            </div>

            <FooterComponent/>
        </div>
    )
}