export const CategoriesJson = [
    {
        "id": "1",
        "imageUrl": "/media/categories/low/walnuts.png",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Walnuts"
            },
            {
                "languageCode": "ru",
                "displayName": "Орехи"
            },
            {
                "languageCode": "zh",
                "displayName": "坚果"
            }
        ]
    },
    {
        "id": "2",
        "imageUrl": "/media/categories/low/preserved-fruits.jpg",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Dried fruits and preserved fruits"
            },
            {
                "languageCode": "ru",
                "displayName": "Сухофрукты и цукаты "
            },
            {
                "languageCode": "zh",
                "displayName": "果干和果脯"
            }
        ]
    },
    {
        "id": "3",
        "imageUrl": "/media/categories/low/seeds.png",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Seeds and kernels"
            },
            {
                "languageCode": "ru",
                "displayName": "Семечки в кожуре и очищенные"
            },
            {
                "languageCode": "zh",
                "displayName": "南瓜籽和瓜子仁"
            }
        ]
    },
    {
        "id": "4",
        "imageUrl": "/media/categories/low/soft-candy.png",
        "name": [
            {
                "languageCode": "en",
                "displayName": "Soft candy"
            },
            {
                "languageCode": "ru",
                "displayName": "Мягкие конфеты"
            },
            {
                "languageCode": "zh",
                "displayName": "软糖"
            }
        ]
    }
]