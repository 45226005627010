import styles from "./FooterComponent.module.scss";
import {HiMail} from "react-icons/hi";
import {ImPhone} from "react-icons/im";
import {defineTextProvider} from "../../extensions/LanguageExtension";

export function FooterComponent() {
    const textProvider = defineTextProvider()

    return (
        <div className={styles.container}>
            <text className={styles.footerText}>@2023 {textProvider.companyName()}</text>
            <div className={styles.contactsContainer}>
                <div className={styles.contactContainer}>
                    <HiMail className={styles.contactIcon}/>
                    <a className={styles.footerText} href={textProvider.companyEmailUrl()}>
                        {textProvider.formattedCompanyEmail()}
                    </a>
                </div>
                <div className={styles.contactContainer}>
                    <ImPhone className={styles.contactIcon}/>
                    <a className={styles.footerText} href={textProvider.companyPhoneUrl()}>
                        {textProvider.formattedCompanyPhone()}
                    </a>
                </div>
            </div>
        </div>
    )
}