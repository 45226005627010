import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import "./App.scss"
import React from "react";
import {ContactsPage} from "../presentation/pages/contacts/ContactsPage";
import {ProductsPage} from "../presentation/pages/products/ProductsPage";
import {ProductPage} from "../presentation/pages/product/ProductPage";
import {MainPage} from "../presentation/pages/main/MainPage";
import {AboutUsPage} from "../presentation/pages/about-us/AboutUsPage";
import {GalleryPage} from "../presentation/pages/gallery/GalleryPage";
import {TestPage} from "../presentation/pages/TestPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                {getRoutes("/")}
                {getRoutes("/:languageCode")}
                <Route path="*" element={<Navigate to="/" replace={true}/>}/>
            </Routes>
        </BrowserRouter>
    )
}

function getRoutes(startPath: string) {
    return <>
        <Route path={startPath + "/"} element={<MainPage/>}/>
        <Route path={startPath + "/test"} element={<TestPage/>}/>
        <Route path={startPath + "/home"} element={<MainPage/>}/>
        <Route path={startPath + "/about-us"} element={<AboutUsPage/>}/>
        <Route path={startPath + "/contacts"} element={<ContactsPage/>}/>
        <Route path={startPath + "/gallery"} element={<GalleryPage/>}/>
        <Route path={startPath + "/products/"} element={<ProductsPage/>}/>
        <Route path={startPath + "/products/:categoryId"} element={<ProductsPage/>}/>
        <Route path={startPath + "/products/:categoryId/:productId"} element={<ProductPage/>}/>
    </>;
}

export default App