import {Language} from "../../data/constants/Language";
import EnTextProvider from "../../core/text-providers/EnTextProvider";
import RuTextProvider from "../../core/text-providers/RuTextProvider";
import ZhTextProvider from "../../core/text-providers/ZhTextProvider";
import TextProvider from "../../core/text-providers/TextProvider";

export function defineCurrentLanguage(): Language {
    const url = window.location.pathname
    const languages = availableLanguages()

    function tryReceiveBrowserLanguage() {
        let browserLanguage = window.navigator.language

        if (browserLanguage.length > 2) {
            browserLanguage = browserLanguage.substring(0, 2)
        }

        return browserLanguage as Language | null
    }

    return languages.find(code => url.startsWith("/" + code + "/"))
        ?? tryReceiveBrowserLanguage()
        ?? Language.EN
}

export function defineTextProvider(language?: Language): TextProvider {
    switch (language ?? defineCurrentLanguage()) {
        case Language.EN:
            return new EnTextProvider()
        case Language.RU:
            return new RuTextProvider()
        case Language.ZH:
            return new ZhTextProvider()
    }
}

export function availableLanguages(): Language[] {
    return [Language.EN, Language.RU, Language.ZH]
}

export function changeLanguage(languageCode: string) {
    const path = window.location.pathname
    const currentLanguage = defineCurrentLanguage()

    let updatedPath = path.replace("/" + currentLanguage, "")
    updatedPath = "/" + languageCode + updatedPath

    window.open(updatedPath, "_self")
}