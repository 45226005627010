import React from "react";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {FooterComponent} from "../../components/footer/FooterComponent";
import styles from "./ProductsPage.module.scss";
import {ProductComponent} from "../../components/product/ProductComponent";
import {ProductsRepository} from "../../../data/repositories/products/ProductsRepository";
import {useParams} from "react-router-dom";
import {CategoriesRepository} from "../../../data/repositories/categories/CategoriesRepository";
import {navigateToProduct} from "../../extensions/NavigationExtension";

export function ProductsPage() {
    const categoryId = useParams().categoryId!!
    const category = new CategoriesRepository().loadCategory(categoryId);
    const products = new ProductsRepository().loadProducts(categoryId);

    return (
        <div>
            <HeaderComponent/>

            <div id={styles.container}>

                <div className={styles.categoryView}>
                    <img src={category.imageUrl}/>
                    <h1>{category.name}</h1>
                </div>

                <div className={styles.productsView}>
                    {products.map(product => (
                        <ProductComponent
                            product={product}
                            onProductClicked={(productId) => navigateToProduct(categoryId, product.id)}
                        />
                    ))}
                </div>

            </div>


            <FooterComponent/>
        </div>
    )
}