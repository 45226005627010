import {Product} from "../../entities/Product";
import {defineCurrentLanguage} from "../../../presentation/extensions/LanguageExtension";
import {NutsJson, PreservedFruitsJson, SoftCandyJson, SeedsJson} from "./SoftCandyJson";

export class ProductsRepository {

    loadProducts(categoryId?: string): Product[] {
        const currentLanguage = defineCurrentLanguage()

        return SoftCandyJson.concat(NutsJson).concat(PreservedFruitsJson).concat(SeedsJson)
            .filter(productJson => {
                return categoryId === undefined || productJson.categoryId === categoryId
            })
            .map(productJson => {
                return {
                    id: productJson.id,
                    categoryId: productJson.categoryId,
                    imageUrl: productJson.imageUrl,
                    name: productJson.name.find((nameVariant) => {
                        return nameVariant.languageCode === currentLanguage;
                    })!!.displayName,
                    origin: productJson.origin.find((nameVariant) => {
                        return nameVariant.languageCode === currentLanguage;
                    })!!.displayName,
                    packaging: productJson.packaging.find((nameVariant) => {
                        return nameVariant.languageCode === currentLanguage;
                    })!!.displayName
                }
            })
    }

    loadProduct(categoryId: string, productId: string): Product {
        const products = this.loadProducts(categoryId)
        return products.find(product => product.id === productId)!!
    }
}