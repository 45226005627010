import styles from "./HeaderComponent.module.scss"
import {DropdownMenuItem} from "../dropdown-menu/DropdownMenuComponent";
import {HeaderMenuItemComponent} from "./menu-item/HeaderMenuItemComponent";
import {HiHome} from "react-icons/hi";
import {FaCartShopping, FaImage} from "react-icons/fa6";
import {AiFillInfoCircle} from "react-icons/ai";
import {PiPhoneCallFill} from "react-icons/pi";
import {CategoriesRepository} from "../../../data/repositories/categories/CategoriesRepository";
import {
    availableLanguages,
    changeLanguage,
    defineCurrentLanguage,
    defineTextProvider
} from "../../extensions/LanguageExtension";
import {
    navigateToAboutUs,
    navigateToContacts,
    navigateToGallery,
    navigateToHome,
    navigateToProducts
} from "../../extensions/NavigationExtension";
import React, {useState} from "react";
import {CgDetailsMore} from "react-icons/cg";
import {IoCloseSharp} from "react-icons/io5";

export function HeaderComponent() {
    const [isMenuShowing, setMenuShowing] = useState(false)

    function onMoreButtonClicked() {
        setMenuShowing(!isMenuShowing)
        document.body.style.overflow = isMenuShowing ? "auto" : "hidden"
    }

    return (
        <div className={styles.container}>
            <img className={styles.companyImage}
                 src={"/media/company/logo.png"}
                 onClick={navigateToHome}/>

            {
                isMenuShowing ? MenuContainer(styles.mobileMenuContainer) : null
            }

            {
                isMenuShowing ?
                    <IoCloseSharp className={styles.moreButton} onClick={onMoreButtonClicked}/> :
                    <CgDetailsMore className={styles.moreButton} onClick={onMoreButtonClicked}/>
            }

            {MenuContainer(styles.menuContainer)}
        </div>
    )
}

export function MenuContainer(className: string) {
    const textProvider = defineTextProvider()

    const menuItems: DropdownMenuItem[] = new CategoriesRepository()
        .loadCategories()
        .map(category => ({id: category.id, title: category.name!!}))

    const languages: DropdownMenuItem[] = availableLanguages()
        .filter(language => language !== defineCurrentLanguage())
        .map(language => ({id: language, title: defineTextProvider(language).language()}))

    return (
        <div className={className}>
            <HeaderMenuItemComponent
                title={textProvider.home()}
                iconType={<HiHome className={styles.menuItemIcon}/>}
                onHeaderMenuItemClicked={navigateToHome}/>

            <HeaderMenuItemComponent
                title={textProvider.products()}
                iconType={<FaCartShopping className={styles.menuItemIcon}/>}
                items={menuItems}
                onHeaderDropdownMenuItemClicked={navigateToProducts}
            />
            <HeaderMenuItemComponent
                title={textProvider.gallery()}
                iconType={<FaImage className={styles.menuItemIcon}/>}
                onHeaderMenuItemClicked={navigateToGallery}
            />
            <HeaderMenuItemComponent
                title={textProvider.aboutUs()}
                iconType={<AiFillInfoCircle className={styles.menuItemIcon}/>}
                onHeaderMenuItemClicked={navigateToAboutUs}
            />
            <HeaderMenuItemComponent
                title={textProvider.contacts()}
                iconType={<PiPhoneCallFill className={styles.menuItemIcon}/>}
                onHeaderMenuItemClicked={navigateToContacts}
            />
            <HeaderMenuItemComponent
                title={textProvider.language()}
                iconType={
                    <img className={styles.menuItemIcon} src={`/media/flags/${textProvider.languageCode()}.svg`}/>
                }
                items={languages}
                onHeaderDropdownMenuItemClicked={(languageCode) => changeLanguage(languageCode)}
            />
        </div>
    )
}