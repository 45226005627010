import TextProvider from "./TextProvider";

export default class RuTextProvider extends TextProvider {

    languageCode(): string {
        return "ru"
    }

    language(): string {
        return "Русский"
    }

    home(): string {
        return "Главная"
    }

    products(): string {
        return "Товары"
    }

    gallery(): string {
        return "Галерея"
    }

    aboutUs(): string {
        return "О нас"
    }

    contacts(): string {
        return "Контакты"
    }

    email(): string {
        return "Электронная почта";
    }

    leaveRequest(): string {
        return "Оставить заявку";
    }

    message(): string {
        return "Сообщение";
    }

    moreDetails(): string {
        return "Подробнее";
    }

    name(): string {
        return "Имя";
    }

    origin(): string {
        return "Происхождение";
    }

    packaging(): string {
        return "Упаковка";
    }

    phone(): string {
        return "Телефон";
    }

    send(): string {
        return "Отправить";
    }

    aboutCompany(): string {
        return "О компании";
    }

    photos(): string {
        return "Фотографии";
    }

    videos(): string {
        return "Видеоматериалы";
    }

    country(): string {
        return "Страна";
    }

    requestSuccess(): string {
        return "Вашя заявки успешно отправлена! Мы свяжемся с Вами в ближайшее время по указанным в заявке контактным данным.";
    }

    requestError(): string {
        return "Произошла ошибка при попытке отправить заявку. Попробуйте еще раз.";
    }

    aboutUsDescription(): string {
        return "\"Karavan Alliance Trading Co Ltd\" - это международная торговая компания, специализирующаяся на продаже различных товаров и услуг. Мы нацелены на установление партнерских отношений с другими компаниями и организациями для взаимодействия и взаимной поддержки.\n\n" +
            "Наша компания строится на принципах качества, надежности и ответственности. Мы предлагаем широкий спектр товаров и услуг, которые удовлетворяют потребности и запросы наших клиентов. Наш опытный и профессиональный персонал стремится предоставить высокий уровень обслуживания и удовлетворить потребности клиентов на глобальном рынке.\n\n" +
            "Мы специализируемся в международной торговле и располагаем обширной сетью партнеров и поставщиков в различных странах. Это позволяет нам оперативно и эффективно предоставлять нашим клиентам качественные товары и услуги по конкурентоспособным ценам.\n\n" +
            "Мы гордимся долгосрочными отношениями с клиентами и партнерами и стремимся к развитию новых партнерств для расширения нашего бизнеса и увеличения его масштаба. Наша компания придерживается принципов деловой этики и профессионализма, и мы стремимся к постоянному росту и успеху в области международной торговли. \n\n";
    }
}
