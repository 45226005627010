import TextProvider from "./TextProvider";

export default class EnTextProvider extends TextProvider {

    languageCode(): string {
        return "en"
    }

    language(): string {
        return "English"
    }

    home(): string {
        return "Home"
    }

    products(): string {
        return "Products"
    }

    gallery(): string {
        return "Gallery"
    }

    aboutUs(): string {
        return "About us"
    }

    contacts(): string {
        return "Contacts"
    }

    email(): string {
        return "Email";
    }

    leaveRequest(): string {
        return "Leave a request";
    }

    message(): string {
        return "Message";
    }

    moreDetails(): string {
        return "More details";
    }

    name(): string {
        return "Name";
    }

    origin(): string {
        return "Origin";
    }

    packaging(): string {
        return "Packaging";
    }

    phone(): string {
        return "Phone";
    }

    send(): string {
        return "Send";
    }

    aboutCompany(): string {
        return "About the company";
    }

    photos(): string {
        return "Photos";
    }

    videos(): string {
        return "Videos";
    }

    country(): string {
        return "Country";
    }

    requestSuccess(): string {
        return "Your application has been successfully sent! We will contact you as soon as possible using the contact details specified in the application.";
    }

    requestError(): string {
        return "An error occurred while trying to submit your application. Try again.";
    }

    aboutUsDescription(): string {
        return "\"Karavan Alliance Trading Co Ltd\" is an international trading company specializing in the sale of various goods and services. We strive to establish partnerships with other companies and organizations for mutual support and collaboration.\n\n" +
            "Our company is built on the principles of quality, reliability, and responsibility. We offer a wide range of products and services that cater to the needs and requests of our clients. Our professional and experienced staff aims to provide a high level of service and meet client needs in the global market.\n\n" +
            "We specialize in international trade and have an extensive network of partners and suppliers in different countries. This allows us to promptly and efficiently provide our clients with quality products and services at competitive prices.\n\n" +
            "We take pride in our long-standing relationships with clients and partners and are committed to developing new partnerships to expand our business and increase its scope. Our company maintains a strong commitment to business ethics and professionalism, and we strive for continual growth and success in the realm of international trade.\n\n";
    }
}
