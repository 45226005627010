import React from "react";
import styles from "./AboutUsPage.module.scss";
import {HeaderComponent} from "../../components/header/HeaderComponent";
import {FooterComponent} from "../../components/footer/FooterComponent";
import {defineTextProvider} from "../../extensions/LanguageExtension";

export function AboutUsPage() {
    const textProvider = defineTextProvider()

    return (
        <div>
            <HeaderComponent/>

            <div className={styles.container}>
                <h1>{textProvider.aboutCompany()}</h1>
                <div className={styles.aboutContainer}>
                    <img src={"/media/company/company-building.jpeg"}/>
                    <text>{textProvider.aboutUsDescription()}</text>
                </div>
            </div>

            <FooterComponent/>
        </div>
    )
}